import { HttpContextToken } from '@angular/common/http';

import { DictionaryModel } from './models/dropdown.model';

export const NAME_ID_SEPARATOR = '##';

export enum FlowStatus {
  ACTIVE = 1,
  DEACTIVATED = 4,
  IN_DESIGN = 5,
}

export enum TimePeriods {
  TODAY = 1,
  WEEK = 2,
  MONTH = 3,
  CUSTOM = 4,
  YESTERDAY = 5,
}

export enum VerificationDecisionedStatus {
  APPROVE = 4,
  DECLINE = 5,
  REVIEW = 6,
}

export enum VerificationStatus {
  APPROVED = 1,
  DECLINED = 2,
  REVIEW = 3,
  DECISIONED_APPROVED = 4,
  DECISIONED_DECLINED = 5,
  DECISIONED_REVIEW = 6,
}

export enum VerificationMode {
  REVIEW,
  MANAGEMENT,
}

export enum TablePeriods {
  DAILY = 1,
  WEEKLY = 2,
  MONTHLY = 3,
}

export const Products: DictionaryModel[] = [
  { id: 1, name: 'Casino' },
  { id: 2, name: 'Sport' },
  { id: 3, name: 'Bingo' },
  { id: 4, name: 'Poker' },
];

export enum TriggerTypeEnum {
  ON_SCHEDULE = 1,
  ON_EVENT = 2,
}

export const TriggerTypeMap = {
  [TriggerTypeEnum.ON_SCHEDULE]: 'on a schedule',
  [TriggerTypeEnum.ON_EVENT]: 'on specific event',
};

export enum MfaMethod {
  MOBILE,
  EMAIL,
}

export const HIDE_ERRORS = new HttpContextToken<string[]>(() => []);

//0 – error alert, 1 – usage, 2 – volume
export enum NotificationRuleType {
  ERROR = 0,
  USAGE = 1,
  VOLUME = 2,
}
export const NotificationTypesMap = {
  [NotificationRuleType.ERROR]: 'Error alerts',
  [NotificationRuleType.USAGE]: 'Usage anomalies',
  [NotificationRuleType.VOLUME]: 'Volume checks',
};

export const NotificationTypesDescription = {
  [NotificationRuleType.ERROR]: 'Receive instant notifications when error thresholds are exceeded.',
  [NotificationRuleType.USAGE]:
    'Receive alerts if the same user is checked by a specific service more than once within the specified period.',
  [NotificationRuleType.VOLUME]:
    'Receive alerts when there are significant deviations in service volumes based on historical data.',
};
